@font-face {
  font-family: 'Saira';
  src: url('fonts/Saira-VariableFont_wdth,wght.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: 'Saira', sans-serif !important;
  /* colors from: https://coolors.co/22223b-4a4e69-9a8c98-c9ada7-f2e9e4 */
  --color-darkest: #22223B;
  --color-dark: #4A4E69;
  --color-middle: #9A8C98;
  --color-light: #C9ADA7;
  --color-lightest: #F2E9E4;
  /* terminal green */
  --color-dark-links: #39ff14;
  --color-dark-links-inverse: #DA14FF;
  /* inverse of terminal amber */
  --color-light-links: #0004FF;

  --bs-body-bg: var(--color-lightest);
  --bs-body-color: var(--color-darkest);
  --fa-border-radius: 10%;
}

a {
  color: var(--color-light-links) !important;
}

h1 {
  color: var(--color-dark) !important;
}

h2,
h3 {
  color: var(--color-middle) !important;
}

.card-entry {
  margin: 1vh;
}

.color-middle {
  color: var(--color-middle);
}

.icon {
  max-width: 50px;
  max-height: 50px;
  border-radius: 10%;
}

.social {
  padding-bottom: 1vh;
  color: var(--color-darkest) !important;
}



@media (prefers-color-scheme: dark) {
  body {
    --bs-body-bg: var(--color-darkest);
    --bs-body-color: var(--color-lightest);
    padding-top: 1%;
  }

  a {
    color: var(--color-dark-links) !important;
  }

  .social {
    color: var(--color-lightest) !important;
  }

  h1 {
    color: var(--color-light) !important;
  }

}



@media (min-width: 768px) {
  .pt-md-up {
    padding-top: 50vh !important;
  }

  .sticky-left-column {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    height: 88vh;
    overflow-y: hidden;
    margin-top: 0% !important;
    margin-bottom: 0% !important;
  }

}